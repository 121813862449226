import React from 'react'

import Layout from 'layouts/Page'
import SEO from 'components/shared/SEO'
import ContactPage from 'components/ContactPage'
import { useNavbarConfig } from 'components/Navbar/NavbarConfig'

export default ({ location }) => {
  useNavbarConfig({ transparent: true, mode: 'stuck' })
  return (
    <Layout navbar="main" location={location} showBeacon={true}>
      <SEO title="Contact Borrowed" />
      <ContactPage />
    </Layout>
  )
}
