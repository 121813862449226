import React from 'react'
import { Parallax, Background } from 'react-parallax'

import styles from './ImageHeader.module.scss'

export default ({ image, title, parallax = true }) => (
  <div className={styles.wrapper}>
    <Parallax
      className={styles.headerWrapper}
      bgImage={image}
      strength={parallax ? 450 : 0}
    >
      <h2 className={styles.title}>{title}</h2>
    </Parallax>
  </div>
)
