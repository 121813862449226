import React from 'react'
import * as routes from 'constants/routes'

import girlContact from '../../../static/img/contact/girl-plant.jpeg'
import styles from './ContactPage.module.scss'

import SupportSection from './SupportSection'
import ImageHeader from 'components/shared/ImageHeader'
import { FormContainer } from 'components/shared/Forms'

export default () => (
  <div>
    <ImageHeader image={girlContact} title="Contact" />
    <div className={styles.welcome}>
      <FormContainer width="800px">
        <SupportSection />
      </FormContainer>
    </div>
  </div>
)
