import React from 'react'
import { Link } from 'gatsby'

import * as routes from 'constants/routes'
import styles from './SupportSection.module.scss'

export default () => (
  <div>
    <div>
      <h3>Event Rentals Planning</h3>
      <p>
        Recieve a quote using <Link to="/quote">this form</Link>
      </p>
    </div>
    <div>
      <h3>Support</h3>
      <p>
        Send us feedback using <Link to="/feedback">this form</Link>
      </p>
      <p>
        Email us as at <a href={`mailto:${routes.EMAIL}`}>{routes.EMAIL}</a>
      </p>
    </div>
    <div>
      <h3>Press & Media</h3>
      <p>Who doesn't like seeing their name in the paper?</p>
      <p>
        Please email <a href={`mailto:${routes.EMAIL}`}>{routes.EMAIL}</a> with
        "Press" in the subject line.
      </p>
    </div>
  </div>
)
